.pt {
    padding-top: 12px;
}

.pb {
    padding-bottom: 12px;
}

.pl {
    padding-left: 7px;
}

.pr {
    padding-right: 7px;
}

.xpl {
    padding-left: 20px;
}

.xpr {
    padding-right: 20px;
}

.xpt {
    padding-top: 20px;
}

.xpb {
    padding-bottom: 20px;
}

.mt {
    margin-top: 12px;
}

.mb {
    margin-bottom: 12px;
}

.ml {
    margin-left: 7px;
}

.mr {
    margin-right: 7px;
}

.xml {
    margin-left: 20px;
}

.xmr {
    margin-right: 20px;
}

.xmt {
    margin-top: 20px;
}

.xmb {
    margin-bottom: 20px;
}
.w100 {
    width: 100%;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.pointer {
    cursor: pointer;
}

.flex-spacer {
    flex: 1 1 auto;
}

table {
    table-layout: fixed;
    background-color: white;
    border-radius: 3px;
    border-collapse: collapse;
    width: 100%;
}

th {
    font-weight: 500;
    font-size: 12px;
    padding: 15px 10px 15px 10px;
    color: #222222;
    text-align: left;
}

td {
    padding: 10px 10px 10px 10px;
    text-align: left;
    border-top: 1px solid #e0e0e0;
}

.hoverRow {
    transition: 0.3s;
    cursor: pointer;
}

.hoverRow:hover {
    background-color: #f3f3f3;
}

.row {
    width: 100%;
    display: flex;
    align-content: center;
}

.col {
    flex: 1 1 0;
}

.right-text {
    text-align: right;
}

.fade-in {
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in-slow {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}