/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "jstyle.scss";

.marker {
  width: 20px;
  height: 20px;
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  background-color: #e91e63;
  border: 1px solid #fff;
  box-shadow: 0 0 15px 0px #000000a6;
}

.mylocation {
  width: 15px;
  height: 15px;
  display: block;
  border: none;
  border-radius: 50%;
  cursor: crosshair;
  padding: 0;
  background-color: #1e63e9;
  border: 1px solid #fff;
  box-shadow: 0 0 15px 0px #000000a0;
}

/*
 * Add scroll bar to select options
 * cf. https://github.com/ionic-team/ionic/issues/17397#issuecomment-496092852
 */
.action-sheet-group::-webkit-scrollbar,
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.action-sheet-group::-webkit-scrollbar-track,
.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.action-sheet-group::-webkit-scrollbar-thumb,
.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #666;
  outline: 1px solid slategrey;
}

.promo_ar, .promo_en {
  background-image: url('/assets/promotion_ar.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: rgb(195, 195, 195);
}

.promo_ar {
  background-image: url('/assets/promotion_ar.jpg');
}

.promo_en {
  background-image: url('/assets/promotion_en.jpg');
}